import React from "react"

import Layout from "../components/Layout"
import SEO from "../components/seo"
import GalleryEmpresarial from "../components/Gallery/Empresarial"

// colocar h1
const IndexPage = () => (
  <Layout>
    <h1 className="sr-only">Fotografo Empresarial</h1>
    <SEO 
      title="Fotografo Empresarial" 
      description="Fotógrafo Empresarial em São Paulo. Fotógrafo profissional com formação na escola Instituto Internacional de Fotografia, atuo em São Paulo nos segmentos da fotografia empresarial, eventos e retrato"
    />
    <GalleryEmpresarial />
  </Layout>
)

export default IndexPage
